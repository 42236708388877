import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import { SketchPicker } from "@hello-pangea/color-picker";

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, formatDate, formatDateWithTime, returnSign } from 'helpers/helpers'
import { GET, POST, PUT, PGET } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [selectedItem, setSelectedItem] = useState({})
	const [organizations, setOrganizations] = useState([])
	const [products, setProducts] = useState([])
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [modalColor, setModalColor] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		random: 0,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/stock-exchange',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/stock-exchange"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.organization_id)
			urlParams += returnSign(urlParams) + 'organization_id=' + filterDataCopy.organization_id
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/stock-exchange${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();

		if (!selectedItem.id) {
			await POST('/stock-exchange', selectedItem, { loader: true })

			setSelectedItem({ 'name': '' })
		} else {
			await PUT('/stock-exchange', selectedItem, { loader: true })

			setSelectedItem({ 'name': '' })
			toggleModal(false)
			toggleModalColor(false)
			searchWithFilter()
		}
	}

	async function handleInputChange(e, index) {
		var dataCopy = { ...data }
		if (e.target.type === 'text') {
			dataCopy.data[index][e.target.name] = e.target.value
		}

		if (e.target.type === 'number') {
			dataCopy.data[index][e.target.name] = e.target.value
		}

		setData(dataCopy)
	}

	async function updateStockExchange(item, type) {
		var itemCopy = { ...item }
		itemCopy.type = type
		if (type === 'price') {
			await PUT('/organizations/products/column', itemCopy, { loader: true })
		}
		if (type === 'sale_text') {
			await PUT('/stock-exchange/products/column', itemCopy, { loader: true })
		}
		if (type === 'sale_description') {
			await PUT('/stock-exchange/products/column', itemCopy, { loader: true })
		}
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
			setShowModal(true)
		} else {
			setSelectedItem({
				'status': true
			})
			setShowModal(false)
		}
	}

	function toggleModalColor(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
		} else {
			setSelectedItem({})
		}

		setModalColor(bool)
	}

	function resetFilter() {
		var filterDataCopy = {
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	async function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getOrganizations() {
		var response = await GET('/organizations-helper', {}, { loader: true })
		if (response) setOrganizations(response)
	}

	async function getProducts() {
		var response = await GET('/products-helper', {}, { loader: true })
		if (response) setProducts(response)
	}

	useEffect(() => {
		if (products.length === 0) {
			getOrganizations()
			getProducts()
		}

	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('stock_exchange')} [{data.total}]
				</h4>
				<button className="btn-rounded btn btn-primary"
					onClick={() => setShowModal(true)}>
					{t('create')}
				</button>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										{reduxAccount.role === "ROLE_ADMIN" &&
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('organizations')}</label>
													<Select
														isClearable
														options={organizations}
														value={organizations.find(option => option.id === filterData.organization_id) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'organization_id': option ? option.id : null })}
														placeholder=""
														noOptionsMessage={() => ""}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										}
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.organization_id &&
									<span className="me-2">
										<b>{t('organization')}: </b>
										{findKeyFromArrayByValue(organizations, 'id', filterData.organization_id, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={() => resetFilter()}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>#</th>
									<th style={{ 'width': '15%' }}>{t('name2')}</th>
									<th style={{ 'width': '5%' }}>{t('price')}</th>
									<th style={{ 'width': '5%' }}>{t('price')}2</th>
									<th style={{ 'width': '5%' }}>{t('text')}</th>
									<th style={{ 'width': '5%' }}>{t('description')}</th>
									<th style={{ 'width': '10%' }}>{t('country')}</th>
									<th style={{ 'width': '20%' }}>{t('manufacturer')}</th>
									<th style={{ 'width': '20%' }}>{t('organization')}</th>
									<th style={{ 'width': '20%' }}>{t('category')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '20%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '5%' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{item.row_number}.</td>
										<td>{item.name}</td>
										<td>
											<input type="number" step="0.01" min="0" className={"auto-width-input table-input"}
												style={{ 'color': item?.price_color }}
												autoComplete="off"
												name="price"
												value={item.price}
												onDoubleClick={() => toggleModalColor(true, item)}
												onChange={(e) => handleInputChange(e, index)} />
										</td>
										<td>
											<div className="d-flex">
												<input type="number" step="0.01" min="0" className={"auto-width-input table-input"}
													style={{ 'color': item?.price_color }}
													autoComplete="off"
													name="price2"
													value={item.price2}
													onDoubleClick={() => toggleModalColor(true, item)}
													onChange={(e) => handleInputChange(e, index)} />
												<div className="table-action-button table-action-success-button"
													onClick={() => updateStockExchange(item, 'price')}>
													<i className="uil-check"></i>
												</div>
											</div>
										</td>
										<td>
											<input type="text" className={"auto-width-input table-input"}
												style={{ 'color': item?.sale_text_color }}
												autoComplete="off"
												name="sale_text"
												value={item.sale_text}
												onDoubleClick={() => toggleModalColor(true, item)}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														updateStockExchange(item, 'sale_text')
													}
												}} />
										</td>
										<td>
											<input type="text" className={"auto-width-input table-input"}
												style={{ 'color': item?.sale_description_color }}
												autoComplete="off"
												name="sale_description"
												value={item.sale_description}
												onDoubleClick={() => toggleModalColor(true, item)}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														updateStockExchange(item, 'sale_description')
													}
												}} />
										</td>
										<td>{item.country_name}</td>
										<td>{item.manufacturer_name}</td>
										<td>{item.organization_name}</td>
										<td>{item.category_name}</td>
										<td className="text-center">
											{!!item.status &&
												<span className="bg-primary bg-border"></span>
											}
											{!!!item.status &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="text-center text-nowrap">
											<span>{formatDateWithTime(item.created_at)}</span>
										</td>
										<td className="d-flex justify-content-end">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('organization')}</label>
							<Select
								isClearable
								options={organizations}
								value={organizations.find(option => option.id === selectedItem.organization_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'organization_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('product')}</label>
							<Select
								isClearable
								options={products}
								value={products.find(option => option.id === selectedItem.product_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'product_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.full_name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('text')}</label>
							<input type="text" className="form-control" name="sale_text"
								value={selectedItem.sale_text}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_text': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('description')}</label>
							<input type="text" className="form-control" name="sale_description"
								value={selectedItem.sale_description}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_description': e.target.value })} />
						</div>
						<div className="d-flex justify-content-between">
							<p className="fw-600 vertical-center">{t('status')}</p>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input" name="status"
									checked={selectedItem?.status}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'status': e.target.checked })} />
							</div>
						</div>

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL COLOR */}
			<Modal show={modalColor} animation={false} centered
				backdrop="static" onHide={() => toggleModalColor(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="d-flex justify-content-between">
							<b className="vertical-center" style={{ 'color': selectedItem.price_color }}>
								{t('price')}
							</b>
							<div className="form-group">
								<div className="position-relative">
									<input type="text" className="form-control"
										value={selectedItem.price_color}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'price_color': e.target.value })} />
									<i className="color-picker-icon uil-angle-down"
										onClick={() => setSelectedItem({ ...selectedItem, 'color_bool': !selectedItem.color_bool })}>
									</i>
								</div>
								{selectedItem.color_bool &&
									<SketchPicker
										className="position-absolute"
										onChangeComplete={(color) => setSelectedItem({ ...selectedItem, 'price_color': color.hex })}
									/>
								}
							</div>
						</div>
						<div className="d-flex justify-content-between">
							<div className="vertical-center" style={{ 'color': selectedItem.sale_text_color }}>
								{t('text')}
							</div>
							<div className="form-group">
								<div className="position-relative">
									<input type="text" className="form-control"
										value={selectedItem.sale_text_color}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_text_color': e.target.value })} />
									<i className="color-picker-icon uil-angle-down"
										onClick={() => setSelectedItem({ ...selectedItem, 'color_bool2': !selectedItem.color_bool2 })}>
									</i>
								</div>
								{selectedItem.color_bool2 &&
									<SketchPicker
										className="position-absolute"
										onChangeComplete={(color) => setSelectedItem({ ...selectedItem, 'sale_text_color': color.hex })}
									/>
								}
							</div>
						</div>

						<div className="d-flex justify-content-between">
							<div className="vertical-center" style={{ 'color': selectedItem.sale_description_color }}>
								{t('description')}
							</div>
							<div className="form-group">
								<div className="position-relative">
									<input type="text" className="form-control"
										value={selectedItem.sale_description_color}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_description_color': e.target.value })} />
									<i className="color-picker-icon uil-angle-down"
										onClick={() => setSelectedItem({ ...selectedItem, 'color_bool3': !selectedItem.color_bool3 })}>
									</i>
								</div>
								{selectedItem.color_bool3 &&
									<SketchPicker
										className="position-absolute"
										onChangeComplete={(color) => setSelectedItem({ ...selectedItem, 'sale_description_color': color.hex })}
									/>
								}
							</div>
						</div>

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalColor(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>

				</Modal.Body>
			</Modal>
			{/* MODAL COLOR */}
		</>
	)
}

export default Index