export let exportMenu = [
	{
		icon: "sidebar-icon uil-chart-growth",
		children: [
			{
				title: true,
				i18n: "STATISTICS",
			},
			{
				url: "/dashboard-main",
				i18n: "HOME",
				icon: "uil-chart-growth",
				active: false,
			},
			{
				url: "/dashboard-cheques",
				i18n: "CHECK_ANALYSIS",
				icon: "uil-invoice",
				active: false,
			},
			{
				url: "/dashboard-expenses",
				i18n: "EXPENSE_ANALYSIS",
				icon: "uil-coins",
				active: false,
			},
			{
				url: "/dashboard-elements",
				i18n: "ELEMENT_ANALYSIS",
				icon: "uil-cell",
				active: false,
			},
			// {
			// 	url: "/dashboard-products",
			// 	i18n: "PRODUCT_ANALYSIS",
			// 	icon: "uil-shopping-cart",
			// 	active: false,
			// },
			{
				title: true,
				i18n: "REPORTS",
			},
			{
				url: "/cheques-all",
				i18n: "CHEQUES",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/cheques-rating",
				i18n: "check_evaluation",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/cheques-by-clients",
				i18n: "client_checks",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-incomes",
				i18n: "GOOD_RECEPTION",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-balance",
				i18n: "RESIDUE",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			// {
			// 	url: "/report-transfer",
			// 	i18n: "TRANSFER",
			// 	icon: "uil-chart-pie-alt",
			// 	active: false,
			// },
			{
				url: "/report-calculation",
				i18n: "CALCULATION",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-agents",
				i18n: "AGENTS",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-clients",
				i18n: "DEBTORS",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-employee",
				i18n: "EMPLOYEES",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-suppliers",
				i18n: "SUPPLIERS",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-organizations",
				i18n: "ORGANIZATIONS",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-menu",
				i18n: "MENU",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-expenses",
				i18n: "EXPENSES",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-safe",
				i18n: "SAFE",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-sales",
				i18n: "SALES",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-gross-income",
				i18n: "GROSS_INCOME",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-cheques-summary",
				i18n: "SUMMARY_CHEQUES",
				icon: "uil-chart-pie-alt",
				active: false,
			},
			{
				url: "/report-summary",
				i18n: "SUMMARY_REPORT",
				icon: "uil-chart-pie-alt",
				active: false,
			},
		]
	},
	{
		icon: "uil-clipboard-notes sidebar-icon",
		children: [
			{
				title: true,
				i18n: "HOME",
			},
			{
				url: "/documents-in",
				i18n: "GOOD_RECEPTION",
				icon: "uil-bring-front",
				active: false,
			},
			{
				url: "/inventory-wms",
				i18n: "INVENTORY",
				icon: "uil-calculator-alt",
				active: false,
			},
			{
				url: "/write-off",
				i18n: "WRITE_OFF",
				icon: "uil-trash-alt",
				active: false,
			},
			{
				url: "/wms-transfer",
				i18n: "TRANSFER",
				icon: "uil-truck",
				active: false,
			},
			{
				title: true,
				i18n: "SERVICES",
			},
			{
				url: "/transfer-to-pos",
				i18n: "moving_another_store",
				icon: "uil-share",
				active: false,
			},
		],
	},
	{
		icon: "uil-setting sidebar-icon",
		children: [
			{
				title: true,
				i18n: "SETTINGS",
			},
			{
				url: "/pos",
				i18n: "POSES",
				icon: "uil-shop",
				active: false,
			},
			{
				url: "/cashbox",
				i18n: "CASHBOXES",
				icon: "uil-store",
				active: false,
			},
			{
				url: "/halls",
				i18n: "HALLS",
				icon: "uil-circle",
				active: false,
			},
			{
				url: "/tables",
				i18n: "TABLES",
				icon: "uil-lamp",
				active: false,
			},
			{
				url: "/menu",
				i18n: "MENU",
				icon: "uil-list-ui-alt",
				active: false,
			},
			{
				url: "/fast-menu",
				i18n: "FAST_MENU",
				icon: "uil-list-ui-alt",
				active: false,
			},
			{
				url: "/elements",
				i18n: "ELEMENTS",
				icon: "uil-cell",
				active: false,
			},
			{
				url: "/products",
				i18n: "PRODUCTS",
				icon: "uil-shopping-cart",
				active: false,
			},
			{
				url: "/price-change",
				i18n: "PRICE_CHANGE",
				icon: "uil-redo",
				active: false,
			},
			{
				url: "/product-order",
				i18n: "ORDER2",
				icon: "uil-sort-amount-up",
				active: false,
			},
			{
				url: "/comments",
				i18n: "COMMENTS",
				icon: "uil-comment",
				active: false,
			},
			{
				url: "/suppliers",
				i18n: "SUPPLIERS",
				icon: "uil-truck-loading",
				active: false,
			},
			{
				url: "/organizations",
				i18n: "ORGANIZATIONS",
				icon: "uil-university",
				active: false,
			},
			{
				url: "/expenses",
				i18n: "EXPENSES",
				icon: "uil-coins",
				active: false,
			},
			{
				url: "/expense-plans",
				i18n: "EXPENSES_PLAN",
				icon: "uil-coins",
				active: false,
			},
			{
				url: "/wms-all",
				i18n: "WAREHOUSES",
				icon: "uil-docker",
				active: false,
			},
			{
				url: "/loyalty/services",
				i18n: "LOYALTY",
				icon: "uil-trophy",
				active: false,
			},
		]
	},
	{
		icon: "uil-user-square sidebar-icon",
		children: [
			{
				title: true,
				i18n: "STAFF",
			},
			{
				url: "/employees",
				i18n: "STAFF",
				icon: "uil-user-square",
				active: false,
			},
			{
				url: "/employee-positions",
				i18n: "POSITIONS",
				icon: "uil-award-alt",
				active: false,
			},
			{
				url: "/employee-bonus",
				i18n: "KPI",
				icon: "uil-favorite",
				active: false,
			},
			{
				url: "/employee-salary",
				i18n: "SALARY",
				icon: "uil-abacus",
				active: false,
			},
			{
				url: "/clients",
				i18n: "CLIENTS",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/agents",
				i18n: "AGENTS",
				icon: "uil-user-md",
				active: false,
			},
			{
				url: "/online-users",
				i18n: "ONLINE_USERS",
				icon: "uil-laptop-cloud",
				active: false,
			},
		]
	},
	{
		icon: "uil-bell sidebar-icon",
		children: [
			{
				title: true,
				i18n: "NOTIFICATION",
			},
			{
				url: "/notification-telegram",
				i18n: "TELEGRAM",
				icon: "uil-telegram",
				active: false,
			},
			{
				url: "/notification-bot",
				i18n: "TELEGRAM_NOTIFICATIONS",
				icon: "uil-telegram",
				active: false,
			},
		]
	},
]