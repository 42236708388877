import React from 'react'

import Login from '../pages/auth/Login'
import ForgotPassword from "../pages/auth/ForgotPassword"

// ADMIN
import Dashboard from '../pages/dashboard/Main'
import Products from '../pages/products/Index'
import Analogs from '../pages/analogs/Index'
import Clients from '../pages/clients/Index'
import Organizations from '../pages/organizations/Index'
import OrganizationProducts from '../pages/organizationProducts/Index'
import StockExchange from '../pages/stockExchange/Index'
import Categories from '../pages/categories/Index'
import Manufacturers from '../pages/manufacturers/Index'
import Countries from '../pages/countries/Index'
import Notifications from '../pages/notifications/Index'
import Analytics from '../pages/analytics/Index'
import Subscriptions from '../pages/subscriptions/Index'

const publicRoutes = [
	//{ path: "/", component: <Login /> },
	{ path: "/auth/login", component: <Login /> },
	{ path: "/auth/forgot-password", component: <ForgotPassword /> },
]

const adminPrivateRoutes = [
	// ADMIN
	{ path: "/admin/dashboard", component: <Dashboard /> },
	{ path: "/admin/products", component: <Products /> },
	{ path: "/admin/analogs", component: <Analogs /> },
	{ path: "/admin/clients", component: <Clients /> },
	{ path: "/admin/organizations", component: <Organizations /> },
	{ path: "/admin/organization-products", component: <OrganizationProducts /> },
	{ path: "/admin/stock-exchange", component: <StockExchange /> },
	{ path: "/admin/manufacturers", component: <Manufacturers /> },
	{ path: "/admin/countries", component: <Countries /> },
	{ path: "/admin/categories", component: <Categories /> },
	{ path: "/admin/notifications", component: <Notifications /> },
	{ path: "/admin/analytics", component: <Analytics /> },
	{ path: "/admin/subscriptions", component: <Subscriptions /> },
]

const privateRoutes = []

export { publicRoutes, adminPrivateRoutes, privateRoutes }