import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import loader from "./loader/reducer"
import skeleton from "./skeleton/reducer"
import filterData from "./filterData/reducer"
import account from "./account/reducer"

const allReducers = combineReducers({
	Layout,
	loader,
	skeleton,
	filterData,
	account,
})

export const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === 'USER_LOGGED_OUT') {
		state = undefined;
	}

	return allReducers(state, action);
};

export default rootReducer
