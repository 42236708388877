import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { GET } from 'helpers/api'
import { formatDateBackend, returnSign, formatMoney } from 'helpers/helpers'

function Products() {
	const { t } = useTranslation()

	const [filterData] = useState({
		start_date: formatDateBackend(new Date()),
		search: '',
	})
	const [tableData, setTableData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/dashboard-products"
		var urlParams = ""

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {})
		setTableData(response)

	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card analytic-card-height">
				<div className="card-body">
					<h5 className="mb-3">{t('products')}</h5>
					<div>
						<table className="table table-sticky">
							<thead>
								<tr>
									<th>#</th>
									<th>{t('name2')}</th>
									<th>{t('country')}</th>
									<th>{t('category')}</th>
									<th>{t('manufacturer')}</th>
									<th className="text-end">{t('min_price')}</th>
								</tr>
							</thead>
							<tbody>
								{tableData.map((item, index) => (
									<tr className="cursor" key={index}>
										<td>{index + 1}</td>
										<td>{item.name}</td>
										<td>{item.country_name}</td>
										<td>{item.category_name}</td>
										<td>{item.manufacturer_name}</td>
										<td className="text-end">{formatMoney(item.min_price)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Products