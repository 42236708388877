import React, { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'

import { showSidebar } from 'store/layout/actions'
import { store } from 'store/index'
//Import Scrollbar
import { exportMenu } from './menu'

import logo from "assets/images/logo.png";
import { Fragment } from "react";

function SidebarContent() {
	const { t } = useTranslation()
	const location = useLocation()

	const reduxAccount = useSelector(state => state.account)

	const [menu, setMenu] = useState(exportMenu)
	const [activeItem, setActiveItem] = useState({
		children: []
	})

	function setActiveMenu() {
		var menuCopy = [...menu]
		for (let k = 0; k < menuCopy.length; k++) {
			for (let i = 0; i < menuCopy[k]['children'].length; i++) {
				if (location.pathname.includes(menuCopy[k]['children'][i]['url'])) {
					menuCopy[k]['children'][i]['active'] = true;
					setActiveItem(menuCopy[k])
				} else {
					menuCopy[k]['children'][i]['active'] = false;
				}
			}
		}
		setMenu(menuCopy);
	}

	function setActiveChild(item, index) {
		store.dispatch(showSidebar())
		var copyArr = [...menu]
		const i = copyArr.findIndex(x => x.icon === activeItem.icon);
		copyArr[i]['children'][index].active = !copyArr[i]['children'][index].active
		setActiveItem(copyArr[i])
	}

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className={"second-sidebar"}>
				<div className="second-sidebar-logo">
					<div className="d-flex">
						<div className="me-2">
							<img src={logo} alt="logo" width={170} />
						</div>
					</div>
				</div>

				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						{activeItem.children.map((item, index) => (
							<Fragment key={index}>
								{((reduxAccount.role === "ROLE_OWNER" && item.role === "ROLE_OWNER") || reduxAccount.role === "ROLE_ADMIN") &&
									<li className={item.active ? "mm-active" : ""}>
										{item.title == null ?
											<NavLink to={item.url}
												onClick={() => setActiveChild(item, index)} className={"waves-effect d-flex " + (item.active ? "active" : "")}>
												<i className={item.icon}></i>
												<div className="vertical-menu-div">{t(item.i18n)}</div>
											</NavLink>
											:
											<div className="second-sidebar-title">
												<div>{t(item.i18n)}</div>
											</div>
										}
									</li>
								}
							</Fragment>
						))}
					</ul>
				</div>
			</div>
		</>
	)
}

export default SidebarContent