import React from 'react'
//import { DebounceInput } from 'react-debounce-input'
import { useTranslation } from 'react-i18next'

function FilterSearch({ filterData, setFilterData }) {
	const { t } = useTranslation()

	async function requestToServer(value) {
		if (value.length === 0) {
			setFilterData({ ...filterData, 'search': value })
		}

		if (value.length > 1) {
			
			setFilterData({ ...filterData, 'search': value })
		}
	};

	return (
		<div className="position-relative">
			<i className="uil-search input-search-icon"></i>
			<input type="text" className="form-control"
				placeholder={t('search') + '...'}
				onKeyUp={(e) => {
					if (e.keyCode === 13) {
						requestToServer(e.target.value)
					}
				}}
			/>
		</div>
	)
}

export default FilterSearch
