import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { formatDate } from 'helpers/helpers'
import { GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({ 'name': '' })
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();

		var response;
		if (!selectedItem.id) {
			response = await POST('/tariffs', selectedItem, { loader: true })
		} else {
			response = await PUT('/tariffs', selectedItem, { loader: true })
		}

		if (response?.status === 200) {
			toggleModal(false)
			getData()
		}
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
		} else {
			setSelectedItem(item)
		}
		setShowModal(bool)
	}

	async function getData() {
		var response = await GET('/tariffs', {}, { loader: true })
		if (response) setData(response)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('subscriptions')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('name2')}</th>
									<th className="text-end">{t('price')}</th>
									<th className="text-end">{t('days')}</th>
									<th className="text-center">{t('date')}</th>
									<th className="text-center">{t('status')}</th>
									<th className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.name}</td>
										<td className="text-end">{item.price}</td>
										<td className="text-end">{item.days}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">
											{!!(item.status) &&
												<span className="bg-primary bg-border"></span>
											}
											{!!(!item.status) &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="d-flex justify-content-end">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						{!selectedItem.id &&
							<div className="form-group">
								<label>{t('price')}</label>
								<input type="text" className="form-control" name="price"
									value={selectedItem.price}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'price': e.target.value })} />
							</div>
						}
						{!selectedItem.id &&
							<div className="form-group">
								<label>{t('days')}</label>
								<input type="text" className="form-control" name="days"
									value={selectedItem.days}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'days': e.target.value })} />
							</div>
						}
						{selectedItem.id &&
							<div className="d-flex justify-content-between mb-2">
								<p className="fw-600 vertical-center">{t('status')}</p>
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input" name="status"
										checked={selectedItem?.status}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'status': e.target.checked })} />
								</div>
							</div>
						}
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index