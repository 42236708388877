import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select, { components } from 'react-select'
import { Editor } from '@tinymce/tinymce-react';
import { SketchPicker } from "@hello-pangea/color-picker";
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, formatDate, formatMoneyInput, returnSign } from 'helpers/helpers'
import { GET, POST, PUT, PGET, FILE, DELETE } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const editorRef = useRef(null);
	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'bool_cash': true,
		'bool_transfer': true,
		'images': [],
	})
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [countries, setCountries] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [products, setProducts] = useState([])
	const [subscriptions, setSubscriptions] = useState([])
	const [data, setData] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalColor, setModalColor] = useState(false)
	const [modalSubscription, setModalSubscription] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		country_id: null,
		category_id: null,
		random: 0,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/organizations/products',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/organizations/products"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.organization_id)
			urlParams += returnSign(urlParams) + 'organization_id=' + filterDataCopy.organization_id
		if (filterDataCopy.country_id)
			urlParams += returnSign(urlParams) + 'country_id=' + filterDataCopy.country_id
		if (filterDataCopy.category_id)
			urlParams += returnSign(urlParams) + 'category_id=' + filterDataCopy.category_id
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/organizations/products${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		var response = await PGET(filterUrl, {}, { loader: true })
		response = JSON.parse(JSON.stringify(response))
		setData(response)
		setPagination({ ...pagination, 'url': filterData.url, 'response': response })

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		if (editorRef?.current?.getContent()) {
			selectedItemCopy.description = editorRef.current.getContent()
		}

		if (!selectedItem.id) {
			await POST('/organizations/products', selectedItemCopy, { loader: true })

			setSelectedItem({
				'name': '',
			})
		} else {
			await PUT('/organizations/products', selectedItemCopy, { loader: true })

			setSelectedItem({
				'name': '',
			})
			toggleModal(false)
			toggleModalColor(false)
			searchWithFilter()
		}
	}

	async function subscribe(e) {
		e.preventDefault();

		var response = await POST('/organizations/products/subscription', selectedItem, { loader: true })
		if (response?.status === 200) {
			toggleModalSubscription(false)
			searchWithFilter()
		}
	}

	async function deleteSubscription() {
		var response = await PUT(`/organizations/products/subscription`, selectedItem, { loader: true })
		if (response?.status === 200) {
			toggleModalSubscription(false)
			searchWithFilter()
		}
	}

	async function refreshPrice() {
		await POST('/organizations/products/price', {
			'organization_id': filterData.organization_id
		}, { loader: true })
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		var selectedItemCopy = { ...selectedItem }
		var response = await FILE(`/organizations/products/image`, formData, {}, { loader: true });
		if (!selectedItemCopy.images) selectedItemCopy.images = []
		selectedItemCopy.images.push({ 'image': response.image })

		setSelectedItem(selectedItemCopy)
		document.getElementById('fileInput').value = '';
	}

	async function deleteImage(item, index) {
		var selectedItemCopy = { ...selectedItem }
		await DELETE(`/organizations/products/image/${item.id}`, {}, { loader: true });

		selectedItemCopy.images.splice(index, 1)
		setSelectedItem(selectedItemCopy)
	}

	async function exportToExcel() {
		var filterUrl = `/organizations/products/excel${filterData.urlParams}`

		var excelHeaders = [["ID", t('name2')]]
		const response = await GET(filterUrl)

		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].id)
			excelItems.push(response[i].name)
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('products') + ".xlsx");
	}

	async function handleInputChange(e, index) {
		var dataCopy = { ...data }
		if (e.target.type === 'text') {
			dataCopy.data[index][e.target.name] = e.target.value
		}

		if (e.target.type === 'number') {
			dataCopy.data[index][e.target.name] = e.target.value
		}

		if (e.target.type === 'checkbox') {
			dataCopy.data[index][e.target.name] = e.target.checked

			await PUT('/organizations/products/show-price', dataCopy.data[index], { loader: true })
		}

		setData(dataCopy)
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			var selectedItemCopy = { ...item }
			if (selectedItemCopy.phone) selectedItemCopy.phone = selectedItemCopy.phone.substring(3, 12)
			setSelectedItem(selectedItemCopy)
			setShowModal(true)
		} else {
			setSelectedItem({
				'name': '',
				'bool_cash': true,
				'bool_transfer': true,
				'images': [],
			})
			setShowModal(false)
		}
	}

	function toggleModalSubscription(bool, item = {}) {
		setSelectedItem({ ...item })
		setModalSubscription(bool)
	}

	function toggleModalColor(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
		} else {
			setSelectedItem({
				'name': '',
				'bool_cash': true,
				'bool_transfer': true,
				'images': [],
			})
		}

		setModalColor(bool)
	}

	async function updateProduct(item, type) {
		var itemCopy = { ...item }
		itemCopy.type = type
		if (type === 'price') {
			await PUT('/organizations/products/column', itemCopy, { loader: true })
		}
		if (type === 'sale_text') {
			await PUT('/organizations/products/column', itemCopy, { loader: true })
		}
		if (type === 'sale_description') {
			await PUT('/organizations/products/column', itemCopy, { loader: true })
		}
	}

	function resetFilter() {
		var filterDataCopy = {
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	async function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getCategories() {
		const response = await GET('/categories-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ` ${response[i]['name']}`
			response[i].child = false;
			if (response[i].parent_id) {
				const index = response.findIndex(x => x.id === response[i].parent_id)
				response[i].child = true;
				if (response[index]?.options && response[index]?.options?.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}

		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getCountries() {
		var response = await GET('/countries-helper', {}, { loader: true })
		if (response) setCountries(response)
	}

	async function getOrganizations() {
		var response = await GET('/organizations-helper', {}, { loader: true })
		if (response) setOrganizations(response)
	}

	async function getProducts() {
		var response = await GET('/products-helper', {}, { loader: true })
		if (response) setProducts(response)
	}

	async function getSubscriptions() {
		var response = await GET('/helper/subscriptions', {}, { loader: true })
		if (response) setSubscriptions(response)
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)

		if (dropdownIsOpen) {
			setFilterData({ ...filterData, 'category_id': option?.id ? option.id : '' })
		}

		if (showModal) {
			setSelectedItem({ ...selectedItem, 'category_id': option?.id ? option.id : '' })
		}
	}

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.categoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.categoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	useEffect(() => {
		if (products.length === 0) {
			getCategories()
			getCountries()
			getOrganizations()
			getProducts()
			getSubscriptions()
		}

	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('change_price')} [{data.total}]
				</h4>
				<div className="d-flex gap-2">
					<button className="btn-rounded btn btn-primary"
						disabled={!(filterData.organization_id)}
						onClick={() => refreshPrice()}>
						{t('refresh_price')}
					</button>
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										{reduxAccount.role === "ROLE_ADMIN" &&
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('organizations')}</label>
													<Select
														isClearable
														options={organizations}
														value={organizations.find(option => option.id === filterData.organization_id) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'organization_id': option ? option.id : null })}
														placeholder=""
														noOptionsMessage={() => ""}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										}
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('countries')}</label>
												<Select
													isClearable
													options={countries}
													value={countries.find(option => option.id === filterData.country_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'country_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => ""}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('category')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.category_id)}
													onChange={(option) => setFilterData({ ...filterData, 'category_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => ""}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2" title="EXCEL"
									onClick={() => exportToExcel()}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.country_id &&
									<span className="me-2">
										<b>{t('country')}: </b>
										{findKeyFromArrayByValue(countries, 'id', filterData.country_id, 'name')}
									</span>
								}
								{filterData.organization_id &&
									<span className="me-2">
										<b>{t('organization')}: </b>
										{findKeyFromArrayByValue(organizations, 'id', filterData.organization_id, 'name')}
									</span>
								}
								{filterData.category_id &&
									<span className="me-2">
										<b>{t('category')}: </b>
										{findKeyFromArrayByValue(categoriesInitial, 'id', filterData.category_id, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={() => resetFilter()}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '15%' }}># {t('name2')}</th>
									<th style={{ 'width': '5%' }}>{t('price')}</th>
									<th style={{ 'width': '5%' }}>{t('text')}</th>
									<th style={{ 'width': '5%' }}>{t('description')}</th>
									<th style={{ 'width': '10%' }}>{t('phone')}</th>
									<th style={{ 'width': '10%' }}>{t('country')}</th>
									<th style={{ 'width': '20%' }}>{t('manufacturer')}</th>
									<th style={{ 'width': '20%' }}>{t('organization')}</th>
									<th style={{ 'width': '20%' }}>{t('category')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('subscription')}</th>
									<th style={{ 'width': '20%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '5%' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{item.row_number}. {item.name}</td>
										<td>
											<input type="number" step="0.01" min="0" className={"auto-width-input table-input"}
												style={{ 'color': item?.price_color }}
												autoComplete="off"
												name="price"
												value={item.price ?? ''}
												onDoubleClick={() => toggleModalColor(true, item)}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														updateProduct(item, 'price')
													}
												}} />
										</td>
										<td>
											<input type="text" className={"auto-width-input table-input"}
												style={{ 'color': item?.sale_text_color }}
												autoComplete="off"
												name="sale_text"
												value={item.sale_text ?? ''}
												onDoubleClick={() => toggleModalColor(true, item)}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														updateProduct(item, 'sale_text')
													}
												}} />
										</td>
										<td>
											<input type="text" className={"auto-width-input table-input"}
												style={{ 'color': item?.sale_description_color }}
												autoComplete="off"
												name="sale_description"
												value={item.sale_description ?? ''}
												onDoubleClick={() => toggleModalColor(true, item)}
												onChange={(e) => handleInputChange(e, index)}
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														updateProduct(item, 'sale_description')
													}
												}} />
										</td>
										<td>{item.phone}</td>
										<td>{item.country_name}</td>
										<td>{item.manufacturer_name}</td>
										<td>{item.organization_name}</td>
										<td>{item.category_name}</td>
										<td className="text-center">
											{!!item.status &&
												<span className="bg-primary bg-border"></span>
											}
											{!!!item.status &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="text-center">
											{!!item.is_top &&
												<span className="bg-primary bg-border"></span>
											}
											{!!!item.is_top &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="text-center text-nowrap">
											<span>{item.created_at}</span>
										</td>
										<td className="d-flex justify-content-end gap-2">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModalSubscription(true, item)}>
												<i className="uil-star"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered size="lg"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label>{t('organization')}</label>
									<Select
										isClearable
										options={organizations}
										value={organizations.find(option => option.id === selectedItem.organization_id) || ''}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'organization_id': option ? option.id : null })}
										placeholder=""
										noOptionsMessage={() => ""}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('product')}</label>
									<Select
										isClearable
										options={products}
										value={products.find(option => option.id === selectedItem.product_id) || ''}
										onChange={(option) => setSelectedItem({ ...selectedItem, 'product_id': option ? option.id : null })}
										placeholder=""
										noOptionsMessage={() => ""}
										getOptionLabel={(option) => option.full_name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								{!selectedItem.id &&
									<div className="form-group">
										<label>{t('price')}</label>
										<input type="text" className="form-control" name="price"
											value={selectedItem.price ? formatMoneyInput(selectedItem.price) : ''}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'price': e.target.value.replace(/[^0-9.-]/g, '') })} />
									</div>
								}
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('cash')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="bool_cash"
											checked={selectedItem?.bool_cash}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'bool_cash': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('transfer')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="bool_transfer"
											checked={selectedItem?.bool_transfer}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'bool_transfer': e.target.checked })} />
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('status')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="status"
											checked={selectedItem?.status}
											onChange={(e) => setSelectedItem({ ...selectedItem, 'status': e.target.checked })} />
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<Editor
									apiKey='0xe5b06q8q8cq3rwaxkhfgt2zi9y1ivbmjqz657m20zpiuz4'
									onInit={(evt, editor) => (editorRef.current = editor)}
									init={{
										height: 200,
										menubar: false,
										plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
										toolbar: 'bold italic | link table',
									}}
									initialValue={selectedItem.description}
								/>

								<div className="con-upload my-2">
									<div className="con-img-upload">
										<div className="con-input-upload">
											<input type="file" id="fileInput" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('image')}
											</span>
										</div>

										{selectedItem?.images?.map((item, index) => (
											<div className="img-upload" key={index}>
												<button type="button" className="btn-x-file"
													onClick={() => deleteImage(item, index)}>
													<i className="uil-times"></i>
												</button>
												<img src={`https://backend.arzonsiryo.uz/uploads/products/${item.image}`}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt={item.image} />
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>

				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL SUBSCRIPTION */}
			<Modal show={modalSubscription} animation={false} centered size="sm"
				backdrop="static" onHide={() => toggleModalSubscription(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>{t('subscription')}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={subscribe} autoComplete="off">
						<div className="form-group">
							<label>{t('subscription')}</label>
							<Select
								isClearable
								options={subscriptions}
								value={subscriptions.find(option => option.id === selectedItem.subscription_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'subscription_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
							<button type="button" className="btn btn-danger w-100"
								onClick={() => deleteSubscription()}
								disabled={!selectedItem.is_top}>
								{t('delete')}
							</button>
						</div>
					</form>

				</Modal.Body>
			</Modal>
			{/* MODAL SUBSCRIPTION */}

			{/* MODAL COLOR */}
			<Modal show={modalColor} animation={false} centered
				backdrop="static" onHide={() => toggleModalColor(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="d-flex justify-content-between">
							<b className="vertical-center" style={{ 'color': selectedItem.price_color }}>
								{t('price')}
							</b>
							<div className="form-group">
								<div className="position-relative">
									<input type="text" className="form-control"
										value={selectedItem.price_color}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'price_color': e.target.value })} />
									<i className="color-picker-icon uil-angle-down"
										onClick={() => setSelectedItem({ ...selectedItem, 'color_bool': !selectedItem.color_bool })}>
									</i>
								</div>
								{selectedItem.color_bool &&
									<SketchPicker
										className="position-absolute"
										onChangeComplete={(color) => setSelectedItem({ ...selectedItem, 'price_color': color.hex })}
									/>
								}
							</div>
						</div>
						<div className="d-flex justify-content-between">
							<div className="vertical-center" style={{ 'color': selectedItem.sale_text_color }}>
								{t('text')}
							</div>
							<div className="form-group">
								<div className="position-relative">
									<input type="text" className="form-control"
										value={selectedItem.sale_text_color}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_text_color': e.target.value })} />
									<i className="color-picker-icon uil-angle-down"
										onClick={() => setSelectedItem({ ...selectedItem, 'color_bool2': !selectedItem.color_bool2 })}>
									</i>
								</div>
								{selectedItem.color_bool2 &&
									<SketchPicker
										className="position-absolute"
										onChangeComplete={(color) => setSelectedItem({ ...selectedItem, 'sale_text_color': color.hex })}
									/>
								}
							</div>
						</div>

						<div className="d-flex justify-content-between">
							<div className="vertical-center" style={{ 'color': selectedItem.sale_description_color }}>
								{t('description')}
							</div>
							<div className="form-group">
								<div className="position-relative">
									<input type="text" className="form-control"
										value={selectedItem.sale_description_color}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'sale_description_color': e.target.value })} />
									<i className="color-picker-icon uil-angle-down"
										onClick={() => setSelectedItem({ ...selectedItem, 'color_bool3': !selectedItem.color_bool3 })}>
									</i>
								</div>
								{selectedItem.color_bool3 &&
									<SketchPicker
										className="position-absolute"
										onChangeComplete={(color) => setSelectedItem({ ...selectedItem, 'sale_description_color': color.hex })}
									/>
								}
							</div>
						</div>

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalColor(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>

				</Modal.Body>
			</Modal>
			{/* MODAL COLOR */}
		</>
	)
}

export default Index