export let exportMenu = [
	{
		icon: "uil-setting sidebar-icon",
		children: [
			{
				title: true,
				i18n: "main",
			},
			{
				url: "/admin/dashboard",
				i18n: "dashboard",
				icon: "uil-chart-growth",
				active: false,
			},
			{
				url: "/admin/clients",
				i18n: "clients",
				icon: "uil-users-alt",
				active: false,
			},
			{
				url: "/admin/products",
				i18n: "products",
				icon: "uil-shopping-cart",
				active: false,
			},
			{
				url: "/admin/analogs",
				i18n: "analogs",
				icon: "uil-equal-circle",
				active: false,
			},
			{
				url: "/admin/organizations",
				i18n: "organizations",
				icon: "uil-university",
				active: false,
				role: "ROLE_OWNER",
			},
			{
				url: "/admin/organization-products",
				i18n: "change_price",
				icon: "uil-redo",
				active: false,
				role: "ROLE_OWNER",
			},
			{
				url: "/admin/stock-exchange",
				i18n: "stock_exchange",
				icon: "uil-building",
				active: false,
				role: "ROLE_OWNER",
			},
			{
				url: "/admin/analytics",
				i18n: "analytics",
				icon: "uil-panel-add",
				active: false,
			},
			{
				url: "/admin/manufacturers",
				i18n: "manufacturers",
				icon: "uil-docker",
				active: false,
				role: "ROLE_OWNER",
			},
			{
				url: "/admin/categories",
				i18n: "categories",
				icon: "uil-list-ui-alt",
				active: false,
			},
			{
				url: "/admin/countries",
				i18n: "countries",
				icon: "uil-globe",
				active: false,
			},
			{
				url: "/admin/notifications",
				i18n: "notifications",
				icon: "uil-bell",
				active: false,
			},
			{
				url: "/admin/subscriptions",
				i18n: "subscriptions",
				icon: "uil-star",
				active: false,
			},
		]
	},
]