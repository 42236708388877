import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatMoney } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()

	const [topData, setTopData] = useState({})
	const [clientTypes, setClientTypes] = useState({
		series: [1, 1, 1, 1],
		options: {
			colors: ['#34c38f', '#f1b44c', '#f46a6a', '#5b73e8'],
			chart: {
				width: 380,
				type: 'pie',
				toolbar: {
					show: false
				},
			},
			labels: [t('observer'), t('buyer'), t('saler'), t('buyer_and_saler')],
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},

		},
	})
	const [show] = useState(!false)
	const [loader, setLoader] = useState(true)
	const [grossIncome] = useState({
		series: [{
			name: 'Прибыл текущий год',
			data: [100, 200, 150, 50, 220, 170, 100, 200, 150, 50, 220, 170]
		}, {
			name: 'Прибыль прошлый год',
			data: [170, 220, 150, 30, 80, 290, 44, 9, 45, 50, 200, 100]
		}],
		options: {
			colors: ['#34c38f', '#f1b44c'],
			chart: {
				height: 350,
				type: 'bar',
				toolbar: {
					show: false
				},
				background: '#fff',
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				tooltip: {
					enabled: false
				},
				categories: [
					'Январь',
					'Февраль',
					'Март',
					'Апрель',
					'Май',
					'Июнь',
					'Июль',
					'Август',
					'Сентябрь',
					'Октябрь',
					'Ноябрь',
					'Декабрь',
				]
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	})
	const [grossIncomeFilter] = useState({
		incomeCurrent: true,
		incomeLast: true,
		show: true,
	})

	async function getData() {
		await getTopData()
		//const responseGrossIncome = await GET('/dashboard-main-income')
		//await getChequeTypes()
	}

	async function getTopData() {
		const response = await GET('/dashboard-main-info')

		setClientTypes({ ...clientTypes, 'series': response['clientType']['series'] })
		setTopData(response)
		setLoader(false)
	}

	useEffect(() => {
		getData()
		//setLoader(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{show &&
				<>
					{!loader &&
						<>
							<div className="row">
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-primary-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('clients')}</h6>
												<div className="text-white">
													{topData.clientsTotal > 0 ?
														<div className="nowrap" title={t('today')}>
															<i className="uil uil-arrow-up"></i>
															+{formatMoney(topData.clientPercentage)}%
														</div>
														:
														<div className="nowrap" title={t('today')}>
															<i className="uil uil-arrow-down"></i>
															{formatMoney(topData.clientPercentage)}%
														</div>
													}
												</div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.clientsTotal)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-danger-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('subscribers')}</h6>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(0)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-warning-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('products')}</h6>
												<div className="text-white"></div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.organizationProductsTotal)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-success-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('change_price')}</h6>
												<div className="text-white"></div>
											</div>
											<div className="d-flex text-white">
												<h4 className="text-nowrap text-white">
													<b>{formatMoney(topData.organizationProductsTotalToday)}</b>
												</h4>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3 d-none">
									<div className="card h-100 bg-danger-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('organization_products')}</h6>
												<div className="text-white"></div>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(0)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-12">
									<div className="card">
										<div className="card-body">
											<h4>{t('profit')}</h4>
											<hr />
											{grossIncomeFilter.show ?
												<ReactApexChart
													type="bar"
													height={300}
													series={grossIncome.series}
													options={grossIncome.options}
												/>
												:
												<Skeleton height={300} />
											}
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-5">
								<div className="col-md-4">
									<div className="card h-100">
										<div className="card-body">
											<div>
												<h4>{t('client_type')}</h4>
												<span>{t('quantity')}</span>
											</div>
											<div className="d-flex justify-content-center">
												<ReactApexChart
													options={clientTypes.options}
													series={clientTypes.series}
													type="pie"
													width={250}
													height={250}
												/>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-success"></div>
													{t('observer')}
												</div>
												<div>{formatMoney(topData['clientType']['observer'])} %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-warning"></div>
													{t('buyer')}
												</div>
												<div>{formatMoney(topData['clientType']['buyer'])} %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-danger"></div>
													{t('saler')}
												</div>
												<div>{formatMoney(topData['clientType']['saler'])} %</div>
											</div>
											<div className="d-flex justify-content-between">
												<div className="d-flex">
													<div className="chart-legend-color bg-primary"></div>
													{t('buyer_and_saler')}
												</div>
												<div>{formatMoney(topData['clientType']['buyer_and_saler'])} %</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card h-100">
										<div className="card-body">
											<div className="mb-3">
												<h4>{t('top_search')}</h4>
												<span>{t('quantity')}</span>
											</div>
											<div className="d-flex w-100">
												<div style={{ 'width': '60%' }}>№ {t('name2')}</div>
												<div className="text-center" style={{ 'width': '20%' }}>{t('quantity')}</div>
												<div className="text-end" style={{ 'width': '20%' }}>{t('yesterday')}</div>
											</div>
											{topData?.clientSearch?.map((item, index) => (
												<div className="d-flex w-100 mb-2" key={index}>
													<div style={{ 'width': '60%' }}>{index + 1}. {item.text}</div>
													<div className="text-center" style={{ 'width': '20%' }}>{formatMoney(item.count)}</div>
													<div className="text-end" style={{ 'width': '20%' }}>
														{item.percentage > 0 ?
															<div className="nowrap text-success">
																<i className="uil uil-arrow-growth"></i>
																{formatMoney(item.percentage, 2)} %
															</div>
															:
															<div className="nowrap text-danger">
																<i className="uil uil-chart-down"></i>
																{formatMoney(item.percentage, 2)} %
															</div>
														}
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card h-100">
										<div className="card-body">
											<div className="mb-3">
												<h4>{t('top interested')}</h4>
												<span>{t('quantity')}</span>
											</div>
											<div className="d-flex w-100">
												<div style={{ 'width': '60%' }}>№ {t('name2')}</div>
												<div className="text-center" style={{ 'width': '20%' }}>{t('quantity')}</div>
												<div className="text-end" style={{ 'width': '20%' }}>{t('yesterday')}</div>
											</div>
											{topData?.clientInterests?.map((item, index) => (
												<div className="d-flex w-100 mb-2" key={index}>
													<div style={{ 'width': '60%', 'lineHeight': '14px' }}>
														{index + 1}. {item.name}
														<div className="ms-3" style={{ 'fontSize': '12px' }}>{item.organization_name}</div>
													</div>
													<div className="text-center" style={{ 'width': '20%' }}>{formatMoney(item.count)}</div>
													<div className="text-end" style={{ 'width': '20%' }}>
														{item.percentage > 0 ?
															<div className="nowrap text-success">
																<i className="uil uil-arrow-growth"></i>
																{formatMoney(item.percentage, 2)} %
															</div>
															:
															<div className="nowrap text-danger">
																<i className="uil uil-chart-down"></i>
																{formatMoney(item.percentage, 2)} %
															</div>
														}
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
								{/* <div className="col-md-4">
									{loader ?
										<Skeleton height={450} />
										:
										<div className="card h-100">
											<div className="card-body">
												<div>
													<h4>{t('CHEQUE_TYPES')}</h4>
													<span>{t('QUANTITY')}</span>
												</div>
												<div className="d-flex justify-content-center">
													<ReactApexChart
														type="pie" width={250} height={250}
														options={chartPieChequeTypes.options}
														series={chartPieChequeTypes.series}
													/>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-success"></div>
														{t('LOYALTY')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_loyalty_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-warning"></div>
														{t('DISCOUNT')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_discount_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-danger"></div>
														{t('SHORTAGE')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_shortage_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-primary"></div>
														{t('NO_PROFIT')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_no_profit_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-purple"></div>
														{t('DEBT')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_debt_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-dark"></div>
														{t('AGENTS')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_agent_percentage)} %</div>
												</div>
												<div className="d-flex justify-content-between">
													<div className="d-flex">
														<div className="chart-legend-color bg-secondary"></div>
														{t('NO_SERVICE_PERCENTAGE')}
													</div>
													<div>{formatMoney(chartPieChequeTypesData.total_no_service_percentage)} %</div>
												</div>
											</div>
										</div>
									}
								</div> */}
							</div>
						</>
					}
					{loader &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
							</div>

							<div className="row mb-3">
								<div className="col-md-12">
									<Skeleton height={300} />
								</div>
							</div>

							<div className="row mb-5">
								<div className="col-md-4">
									<Skeleton height={450} />
								</div>
								<div className="col-md-4">
									<Skeleton height={450} />
								</div>
								<div className="col-md-4">
									<Skeleton height={450} />
								</div>
							</div>
						</>
					}
				</>
			}
		</>
	)
}

export default Index
