import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { POST, PUT, PGET, GET } from 'helpers/api'
import { formatDate, regexPhoneNumber, returnSign, formatDateBackend } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const editorRef = useRef(null);
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		registration_type: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/organizations',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/organizations"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/organizations${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}

		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var filterUrl = "/organizations"
		var urlParams = ""

		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		filterUrl = "/organizations/excel" + urlParams + returnSign(filterUrl)

		var excelHeaders = [[t('phone'), t('name'), t('address')]]
		const response = await GET(filterUrl)
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i]['phone'])
			excelItems.push(response[i]['address'])
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('organizations') + ".xlsx");
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			start_date: null,
			end_date: null,
			search: '',
		})
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function createUpdate(e) {
		e.preventDefault()
		var sendData = { ...selectedItem }

		sendData.description = editorRef.current.getContent()

		var response;
		if (sendData.id) {
			response = await PUT('/organizations', sendData, { loader: true })
		} else {
			response = await POST('/organizations', sendData, { loader: true })
		}

		if (response?.status === 200) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			var itemCopy = { ...item }
			setSelectedItem(itemCopy)
			setShowModal(true)
		} else {
			setShowModal(false)
		}
	}

	function addToList() {
		var selectedItemCopy = { ...selectedItem }
		var obj = { 'phone': '' }
		if (!selectedItemCopy.phones) {
			selectedItemCopy.phones = []
		}
		selectedItemCopy.phones.push(obj)
		setSelectedItem(selectedItemCopy)
	}

	function deleteItem(index) {
		var selectedItemCopy = { ...selectedItem }

		selectedItemCopy.phones.splice(index, 1)
		setSelectedItem(selectedItemCopy)
	}

	function handleInputChange(e, index) {
		var selectedItemCopy = { ...selectedItem }

		if (e.target.type === 'text') {
			selectedItemCopy.phones[index][e.target.name] = e.target.value
		}
		if (e.target.type === 'number') {
			selectedItemCopy.phones[index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		}
		setSelectedItem(selectedItemCopy)
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('organizations')} [{data.total}]
				</h4>
				{reduxAccount.role === "ROLE_ADMIN" &&
					<div className="d-flex">
						<button className="btn-rounded btn btn-primary"
							onClick={() => toggleModal(true)}>
							<i className="uil uil-plus me-2"></i>
							{t('create')}
						</button>
					</div>
				}
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex gap-2 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide-2" title="EXCEL"
								onClick={() => searchWithFilter(true)}>
								<i className="uil uil-file-download-alt"></i>
							</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('name2')}</th>
									<th>{t('phone')}</th>
									<th>{t('tin')}</th>
									<th>{t('address')}</th>
									<th className="text-center">{t('note')}</th>
									<th className="text-center">{t('on_market')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('is_verified')}</th>
									<th className="text-center">{t('action')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td title={`ID: ${item.id}`}>
											{item.row_number}. {item.name}
										</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td>{item.tin}</td>
										<td>{item.address}</td>
										<td className="text-center">{item.note}</td>
										<td className="text-center">{item.year}</td>
										<td className="text-center">
											{!!(item.status) &&
												<span className="bg-primary bg-border"></span>
											}
											{!!(!item.status) &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="text-center">
											{!!(item.is_verified) &&
												<span className="bg-primary bg-border"></span>
											}
											{!!(!item.is_verified) &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal
				show={showModal}
				animation={false}
				size="xl"
				centered
				backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('edit')}</>
							:
							<>{t('create')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row">
							<div className="col-md-6">
								<div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t('login')}</label>
											<input type="text" className="form-control" name="login"
												disabled={selectedItem.login_exist}
												value={selectedItem.login ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'login': e.target.value })} />
										</div>
										<div className="form-group w-100">
											<label>{t('password')}</label>
											<input type="text" className="form-control" name="password"
												value={selectedItem.password ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })} />
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t('name2')}<span className="required-mark">*</span></label>
											<input type="text" className="form-control" name="name"
												value={selectedItem.name ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
										</div>
										<div className="form-group w-100">
											<label>{t('phone')}</label>
											<input type="number" className="form-control"
												value={selectedItem.phone ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-50">
											<label>{t('link')} Telegram</label>
											<input type="text" className="form-control" name="tg_link"
												value={selectedItem.tg_link ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'tg_link': e.target.value })} />
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t('tin')}</label>
											<input type="number" className="form-control" name="tin"
												value={selectedItem.tin ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'tin': e.target.value })} />
										</div>

										<div className="form-group w-100">
											<label>{t('address')}</label>
											<input type="text" className="form-control" name="address"
												value={selectedItem.address ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'address': e.target.value })} />
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="form-group w-100">
											<label>{t('note')}</label>
											<input type="text" className="form-control" name="note"
												value={selectedItem.note ?? ''}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
										</div>
										<div className="form-group w-100">
											<label>{t('date')}</label>
											<DatePicker
												className="form-control text-center"
												selected={selectedItem.created_year ? new Date(selectedItem.created_year) : ''}
												onChange={(date) => setSelectedItem({ ...selectedItem, 'created_year': date })}
												showYearPicker
												dateFormat="yyyy"
												minDate={new Date(1990, 0, 1)}
												maxDate={new Date()}
											/>
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('status')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="status"
												checked={selectedItem?.status}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'status': e.target.checked })} />
										</div>
									</div>
									<div className="d-flex justify-content-between mb-2">
										<p className="fw-600 vertical-center">{t('is_verified')}</p>
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input" name="is_verified"
												checked={selectedItem?.is_verified}
												onChange={(e) => setSelectedItem({ ...selectedItem, 'is_verified': e.target.checked })} />
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<Editor
									apiKey='0xe5b06q8q8cq3rwaxkhfgt2zi9y1ivbmjqz657m20zpiuz4'
									onInit={(evt, editor) => (editorRef.current = editor)}
									init={{
										height: 200,
										menubar: false,
										plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
										toolbar: 'bold italic | link table',
									}}
									initialValue={selectedItem.description}
								/>
								<div className="d-flex justify-content-between mt-3">
									<div className="vertical-center">
										<h5><b>{t('additional_phones')}</b></h5>
									</div>
									<div className="d-flex justify-content-center">
										<div className="table-action-button table-action-primary-button"
											onClick={() => addToList()}>
											<i className="uil-plus"></i>
										</div>
									</div>
								</div>
								{selectedItem?.phones?.map((item, index) => (
									<div className="d-flex gap-2" key={index}>
										<div className="form-group w-100">
											<input type="number" className="form-control" name="phone"
												placeholder={t('phone')}
												value={item.phone ?? ''}
												onChange={(e) => handleInputChange(e, index)} />
										</div>
										<div className="form-group w-100">
											<input type="text" className="form-control" name="name"
												placeholder={t('name')}
												value={item.name ?? ''}
												onChange={(e) => handleInputChange(e, index)} />
										</div>
										<div className="vertical-center">
											<div className="vertical-center">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteItem(index)}>
													<i className="uil uil-trash-alt"></i>
												</div>
											</div>
										</div>
									</div>
								))}

							</div>

							<div className="d-flex gap-2 w-100 mt-3">
								<button type="button" className="btn btn-outline-warning w-100"
									onClick={() => toggleModal(false)}>
									{t('cancel')}
								</button>
								<button type="submit" className="btn btn-primary w-100">
									{t('save')}
								</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
