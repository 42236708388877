import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import ReactApexChart from 'react-apexcharts'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatDate, formatDateBackend, formatMoney, regexPhoneNumber, returnSign } from 'helpers/helpers'

function UsersInterested() {
	const { t } = useTranslation()
	const date = new Date()

	const [loader, setLoader] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

	const [filterData, setFilterData] = useState({
		start_date: formatDateBackend(date.setDate(date.getDate() - 30)),
		end_date: formatDateBackend(new Date()),
		search: '',
	})

	const [chartData, setChartData] = useState({
		series: [{
			name: '',
			data: []
		}],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					distributed: true,
				}
			},
			xaxis: {
				categories: [],
			},
			legend: {
				show: false
			},
		},
	})
	const [tableData, setTableData] = useState([])

	async function searchWithFilter() {
		setLoader(true)

		var filterDataCopy = { ...filterData }
		var filterUrl = "/dashboard-clients-interest"
		var urlParams = ""

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {})

		var chartDataCopy = { ...chartData }
		chartDataCopy.series[0]['data'] = response['chart']['series']
		chartDataCopy.options.xaxis.categories = response['chart']['categories']

		setChartData(chartDataCopy)
		setTableData(response.data)

		setLoader(false)
	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div className="card analytic-card-height">
					<div className="card-body">
						<div className="mb-2">
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													maxDate={new Date()}
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													maxDate={new Date()}
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter()}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>

						{loader ?
							<Skeleton height={315} />
							:
							<ReactApexChart
								options={chartData.options}
								series={chartData.series}
								type="bar"
								height={window.innerHeight - 230}
							/>
						}
					</div>
				</div>

				<div className="card analytic-card-height">
					<div className="card-body">
						<table className="table table-sticky">
							<thead>
								<tr>
									<th>#</th>
									<th>{t('name')}</th>
									<th className="text-start">{t('phone')}</th>
									<th>{t('name2')}</th>
									<th className="text-end">{t('quantity')}</th>
									<th className="text-end">{t('date')}</th>
								</tr>
							</thead>
							<tbody>
								{tableData?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{item.client_name}</td>
										<td className="text-start">{regexPhoneNumber(item.phone)}</td>
										<td>{item.product_name}</td>
										<td className="text-end">{formatMoney(item.count)}</td>
										<td className="text-end">{formatDate(item.created_at)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default UsersInterested