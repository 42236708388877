import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { formatDate } from 'helpers/helpers'
import { GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const sorts = [
		{ 'id': 1, 'name': "1-Сорт" },
		{ 'id': 2, 'name': "2-Сорт" }
	]

	const [selectedItem, setSelectedItem] = useState({
		'name_ru': '',
		'name_uz': '',
		'name_en': '',
		'show': true,
		'parent_id': null,
	})
	const [data, setData] = useState([])
	const [categories, setCategories] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();

		if (!selectedItem.id) {
			await POST('/categories', selectedItem, { loader: true })
		} else {
			await PUT('/categories', selectedItem, { loader: true })
		}

		setSelectedItem({
			'name_ru': '',
			'name_uz': '',
			'name_en': '',
			'parent_id': null,
		})
		toggleModal(false)
		getData()
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			var selectedItemCopy = { ...item }
			setSelectedItem(selectedItemCopy)
			setShowModal(true)
		} else {
			setSelectedItem({
				'name_ru': '',
				'name_uz': '',
				'name_en': '',
				'show': true,
				'parent_id': null,
			})
			setShowModal(false)
		}
	}

	async function getData() {
		var response = await GET('/categories', {}, { loader: true })
		var responseCategories = await GET('/categories-helper', {}, { loader: true })
		if (response) {
			setData(response)

			responseCategories.unshift({ 'id': null, 'name': t('main_category') })
			setCategories(responseCategories)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('categories')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '15%' }}>№ {t('name2')} ru</th>
									<th style={{ 'width': '15%' }}>{t('name2')} uz</th>
									<th style={{ 'width': '15%' }}>{t('name2')} en</th>
									<th style={{ 'width': '15%' }}>{t('sort')}</th>
									<th style={{ 'width': '15%' }}>{t('category')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '5%' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.name_ru}</td>
										<td>{item.name_uz}</td>
										<td>{item.name_en}</td>
										<td>
											{item.sort === 1 &&
												<span>1 - {t('sort')}</span>
											}
											{item.sort === 2 &&
												<span>2 - {t('sort')}</span>
											}
										</td>
										<td>
											{!item.parent_name &&
												<span>{t('main_category')}</span>
											}
											{item.parent_name &&
												<span>{item.parent_name}</span>
											}
										</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">
											{item.show &&
												<span className="bg-primary bg-border"></span>
											}
											{!item.show &&
												<span className="bg-danger bg-border"></span>
											}
										</td>
										<td className="d-flex justify-content-end">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')} ru</label>
							<input type="text" className="form-control" name="name_ru" value={selectedItem.name_ru}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name_ru': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('name2')} uz</label>
							<input type="text" className="form-control" name="name_uz" value={selectedItem.name_uz}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name_uz': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('name2')} en</label>
							<input type="text" className="form-control" name="name_en" value={selectedItem.name_en}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name_en': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('sort')}</label>
							<Select
								options={sorts}
								value={sorts.find(option => option.id === selectedItem.sort)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'sort': option.id })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('category')}</label>
							<Select
								options={categories}
								value={categories.find(option => option.id === selectedItem.parent_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'parent_id': option.id })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex justify-content-between mb-2">
							<p className="fw-600 vertical-center">{t('status')}</p>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input" name="show"
									checked={selectedItem?.show}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'show': e.target.checked })} />
							</div>
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index