
import ru from "../assets/images/flags/ru.png"
import uzLatn from "../assets/images/flags/uz.png"

const languages = {
  ru: {
    label: "ru",
    i18n: "russian",
    flag: ru,
  },
	uzLatn: {
    label: "uz",
		i18n: "uz",
    flag: uzLatn,
  },
}

export default languages
