import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import ReactApexChart from 'react-apexcharts'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatDate, formatDateBackend, regexPhoneNumber, returnSign } from 'helpers/helpers'

function UsersActivity() {
	const { t } = useTranslation()
	const date = new Date()

	const [loader, setLoader] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	
	const [filterData, setFilterData] = useState({
		start_date: formatDateBackend(date.setDate(date.getDate() - 30)),
		end_date: formatDateBackend(new Date()),
		search: '',
	})
	const [chartData, setChartData] = useState({
		series: [
			{
				name: "Пользователи",
				data: [5, 10, 18, 7, 24, 37, 20, 10, 9, 25, 35, 32, 13, 24, 20, 26, 37, 18, 39, 40, 21, 42, 34, 27, 64, 45, 51, 20, 70, 54]
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'line',
				dropShadow: {
					enabled: true,
					color: '#000',
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2
				},
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: true,
				background: {
					enabled: true,
					borderRadius: 10,
				},
			},
			stroke: {
				// curve: 'smooth'
			},
			markers: {
				size: 1
			},
			xaxis: {
				categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
			},
			legend: {
				show:false
			}
		},
	})
	const [tableData, setTableData] = useState([])

	async function searchWithFilter() {
		setLoader(true)

		var filterDataCopy = { ...filterData }
		var filterUrl = "/dashboard-clients-activity"
		var urlParams = ""

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {})

		var chartDataCopy = { ...chartData }
		chartDataCopy.series[0]['data'] = response['chart']['series']
		chartDataCopy.options.xaxis.categories = response['chart']['categories']
		setChartData(chartDataCopy)
		setTableData(response.data)

		setLoader(false)
		setDropdownIsOpen(false)
	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div className="card analytic-card-height">
					<div className="card-body">
						<div className="mb-2">
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													maxDate={new Date()}
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													maxDate={new Date()}
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter()}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>

						{loader ?
							<Skeleton height={315} />
							:
							<ReactApexChart
								options={chartData.options}
								series={chartData.series}
								type="line"
								height={window.innerHeight - 230}
							/>
						}
					</div>
				</div>

				<div className="card w-50 analytic-card-height">
					<div className="card-body">
							<table className="table table-sticky">
								<thead>
									<tr>
										<th>#</th>
										<th>{t('name')}</th>
										<th className="text-start">{t('phone')}</th>
										<th className="text-end">{t('last_login')}</th>
									</tr>
								</thead>
								<tbody>
									{tableData.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{item.name}</td>
											<td className="text-start">{regexPhoneNumber(item.phone)}</td>
											<td className="text-end">{formatDate(item.created_at)}</td>
										</tr>
									))}
								</tbody>
							</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default UsersActivity