import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from 'react-apexcharts'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatDateBackend, returnSign, formatMoney } from 'helpers/helpers'
import useDidMountEffect from 'pages/parts/useDidMountEffect'

function OrganizationsProducts() {
	const { t } = useTranslation()

	const [loader, setLoader] = useState(false)
	const [organizations, setOrganizations] = useState([])
	const [filterData] = useState({
		start_date: formatDateBackend(new Date()),
		search: '',
	})
	const [chartData, setChartData] = useState({
		name: '',
		series: [
			{
				name: '',
				data: []
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					distributed: true,
				}
			},
			xaxis: {
				categories: [],
			},
			legend: {
				show: false,
			},
		},
	})
	const [tableData, setTableData] = useState([])

	async function searchWithFilter(id) {
		setLoader(true)

		var filterDataCopy = { ...filterData }
		var filterUrl = `/dashboard-position/${id}`
		var urlParams = ""

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await GET(filterUrl, {}, { loader: true })

		setTableData(response.data)
		setLoader(false)
	}

	function handleClickOrganization(item) {
		var organizationsCopy = [...organizations]
		for (let i = 0; i < organizationsCopy.length; i++) {
			if (organizationsCopy[i]['id'] === item.id) {
				organizationsCopy[i]['selected'] = true
				searchWithFilter(item.id)
			} else {
				organizationsCopy[i]['selected'] = false
			}
		}
	}

	async function handleClickProduct(item) {
		setLoader(true)

		var filterUrl = `/dashboard-position-detail/${item.product_id}`
		var response = await GET(filterUrl, {})

		var chartDataCopy = { ...chartData }
		chartDataCopy.series[0]['data'] = response['chart']['series']
		chartDataCopy.options.xaxis.categories = response['chart']['categories']
		chartDataCopy.name = item.name
		setChartData(chartDataCopy)

		setLoader(false)
	}

	async function getOrganizations() {
		var response = await GET('/organizations-helper', {})
		if (response) {
			setOrganizations(response)
			setTimeout(() => {

			}, 300);
		}
	}

	useEffect(() => {
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		handleClickOrganization(organizations[0])
	}, [organizations]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		console.log(tableData);
		handleClickProduct(tableData[0])
	}, [tableData]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div className="card w-50 analytic-card-height">
					<div className="card-body">
						<h5 className="mb-3">{t('organizations')}</h5>
						<div>
							{organizations.map((item, index) => (
								<div className={`report-pos ${item.selected && 'active'}`}
									key={index}
									onClick={() => handleClickOrganization(item)}>
									{item.name}
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="card w-100 analytic-card-height">
					<div className="card-body">
						<h5 className="mb-3">{t('products')} [{organizations?.find(item => item.selected)?.name}]</h5>
						<table className="table table-sticky">
							<thead>
								<tr>
									<th>#</th>
									<th>{t('name2')}</th>
									<th className="text-end">{t('price')}</th>
									<th className="text-end">{t('min_price')}</th>
									<th className="text-end">{t('max_price')}</th>
								</tr>
							</thead>
							<tbody>
								{tableData.map((item, index) => (
									<tr className="cursor" key={index}
										onClick={() => handleClickProduct(item)}>
										<td>{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-end">{formatMoney(item.min_price)}</td>
										<td className="text-end">{formatMoney(item.max_price)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				<div className="card w-100 analytic-card-height">
					<div className="card-body">
						<h5 className="mb-3">{t('graph')} [{chartData.name}]</h5>
						{loader ?
							<Skeleton height={315} />
							:
							<ReactApexChart
								options={chartData.options}
								series={chartData.series}
								type="bar"
								height={window.innerHeight - 220}
							/>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default OrganizationsProducts