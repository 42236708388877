import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
		'parent_id': null,
		'analogs': [],
	})
	const [data, setData] = useState([])
	const [products, setProducts] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();

		if (!selectedItem.id) {
			await POST('/analogs', selectedItem, { loader: true })
		} else {
			await PUT('/analogs', selectedItem, { loader: true })
		}

		setSelectedItem({
			'name': '',
			'parent_id': null,
			'analogs': [],
		})
		toggleModal(false)
		getData()
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			var selectedItemCopy = { ...item }
			setSelectedItem(selectedItemCopy)
			setShowModal(true)
		} else {
			setSelectedItem({
				'name': '',
				'parent_id': null,
				'analogs': [],
			})
			setShowModal(false)
		}
	}

	async function getData() {
		var response = await GET('/analogs', {}, { loader: true })
		setData(response)
	}

	async function getProducts() {
		var response = await GET('/products-helper', {}, { loader: true })
		if (response) setProducts(response)
	}

	useEffect(() => {
		getData()
		getProducts()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('analogs')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '15%' }}>№ {t('name2')}</th>
									<th style={{ 'width': '15%' }}>{t('analogs')}</th>
									<th style={{ 'width': '75%' }}>{t('analogs')}</th>
									<th style={{ 'width': '5%' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.name}</td>
										<td>{item?.analogs?.length}</td>
										<td>
											{item?.analogs?.map((child, childIndex) => (
												<span key={childIndex}>{child.full_name},</span>
											))}
										</td>
										<td className="d-flex justify-content-end">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('analogs')}</label>
							<Select
								isMulti
								value={selectedItem.analogs}
								options={products}
								onChange={(options) => setSelectedItem({ ...selectedItem, 'analogs': options })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.full_name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index