import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { returnSign, formatMoney, formatDateWithTime, formatDateBackend } from "helpers/helpers";
import { POST, PGET, FILE } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/notifications',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/notifications"
		var urlParams = ""
		checkFilter()

		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/notifications${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		setData(response)
		setPagination({ ...pagination, 'url': filterData.url, 'response': response })

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function createUpdate(e) {
		e.preventDefault();

		const response = await POST('/notifications-telegram-send', selectedItem, { loader: true })

		if (response) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("id", selectedItem.id);
		formData.append("image", e.target.files[0]);
		formData.append("path", 'notifications');

		const response = await FILE('/upload-image', formData, {}, { loader: true });
		setSelectedItem({ ...selectedItem, 'image': response.url })
	}

	function updateModal(bool) {
		if (bool) {
			setShowModal(true)
		} else {
			setShowModal(false)
		}
		setSelectedItem({})
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('notifications')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('date')}</th>
									<th>{t('image')}</th>
									<th>{t('description')}</th>
									<th className="text-end">{t('errors')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-nowrap">{formatDateWithTime(item.created_at)}</td>
										<td>
											<img src={`https://backend.arzonsiryo.uz/uploads/notifications/${item.image}`}
												width={50} height={50} alt="img" />
										</td>
										<td>{item.message}</td>
										<td className="text-end">{formatMoney(item.errors_count)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => updateModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('create')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="con-upload position-relative mb-2">
							<div className="con-img-upload ">
								{selectedItem.image &&
									<div className="img-upload">
										<button type="button" className="btn-x-file" onClick={() => setSelectedItem({ ...selectedItem, 'image': '' })}>
											<i className="uil-times"></i>
										</button>
										<img src={`https://backend.arzonsiryo.uz/uploads/notifications/${selectedItem.image}`}
											style={{ maxWidth: 'none', maxHeight: '100%' }} alt={selectedItem.image} />
									</div>
								}
								{!selectedItem.image &&
									<div className="con-input-upload">
										<input type="file" onChange={(e) => fileUpload(e)} />
										<span className="text-input fz14">
											{t('image')} JPG!
										</span>
									</div>
								}
							</div>
						</div>
						<div className="form-group">
							<label>{t("description")}</label>
							<textarea className="form-control" rows="5"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'message': e.target.value })}>
							</textarea>
						</div>

						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => updateModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index