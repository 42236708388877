import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select, { components } from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, formatDate, returnSign } from 'helpers/helpers'
import { GET, POST, PUT, PGET } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'name': '',
	})
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [countries, setCountries] = useState([])
	const [manufacturers, setManufacturers] = useState([])
	const [analogs, setAnalogs] = useState([])
	const [data, setData] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		country_id: null,
		category_id: null,
		random: 0,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/products',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/products"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.country_id)
			urlParams += returnSign(urlParams) + 'country_id=' + filterDataCopy.country_id
		if (filterDataCopy.category_id)
			urlParams += returnSign(urlParams) + 'category_id=' + filterDataCopy.category_id
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/products${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }
		if (selectedItemCopy.phone) selectedItemCopy.phone = '998' + selectedItemCopy.phone

		var response
		if (!selectedItem.id) {
			response = await POST('/products', selectedItemCopy, { loader: true })
		} else {
			response = await PUT('/products', selectedItemCopy, { loader: true })
		}

		if (response?.status === 200) {
			searchWithFilter()
			toggleModal(false)
		}
	}

	async function exportToExcel() {
		var filterUrl = `/products/excel${filterData.urlParams}`

		var excelHeaders = [["ID", t('name2')]]
		const response = await GET(filterUrl)

		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].id)
			excelItems.push(response[i].name)
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('products') + ".xlsx");
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			var selectedItemCopy = { ...item }
			if (selectedItemCopy.phone) selectedItemCopy.phone = selectedItemCopy.phone.substring(3, 12)
			setSelectedItem(selectedItemCopy)
			setShowModal(true)
		} else {
			setSelectedItem({
				'name': '',
			})
			setShowModal(false)
		}
	}

	function resetFilter() {
		var filterDataCopy = {
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	async function checkFilter() {
		if (
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getCategories() {
		const response = await GET('/categories-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ` ${response[i]['name']}`
			response[i].child = false;
			if (response[i].parent_id) {
				const index = response.findIndex(x => x.id === response[i].parent_id)
				response[i].child = true;
				if (response[index]?.options && response[index]?.options?.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}

		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getCountries() {
		var response = await GET('/countries-helper', {}, { loader: true })
		if (response) setCountries(response)
	}

	async function getManufacturers() {
		var response = await GET('/manufacturers-helper', {}, { loader: true })
		if (response) setManufacturers(response)
	}

	async function getAnalogs() {
		var response = await GET('/helper/analogs', {}, { loader: true })
		if (response) setAnalogs(response)
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)

		if (dropdownIsOpen) {
			setFilterData({ ...filterData, 'category_id': option?.id ? option.id : '' })
		}

		if (showModal) {
			setSelectedItem({ ...selectedItem, 'category_id': option?.id ? option.id : '' })
		}
	}

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.categoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.categoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	useEffect(() => {
		getCategories()
		getCountries()
		getAnalogs()
		getManufacturers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('products')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('countries')}</label>
												<Select
													isClearable
													options={countries}
													value={countries.find(option => option.id === filterData.country_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'country_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => ""}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('category')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.category_id)}
													onChange={(option) => setFilterData({ ...filterData, 'category_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => ""}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>
														{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2" title="EXCEL"
									onClick={() => exportToExcel()}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.country_id &&
									<span className="me-2">
										<b>{t('country')}: </b>
										{findKeyFromArrayByValue(countries, 'id', filterData.country_id, 'name')}
									</span>
								}
								{filterData.category_id &&
									<span className="me-2">
										<b>{t('category')}: </b>
										{findKeyFromArrayByValue(categoriesInitial, 'id', filterData.category_id, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={() => resetFilter()}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '15%' }}>№ {t('name2')}</th>
									<th style={{ 'width': '15%' }}>{t('country')}</th>
									<th style={{ 'width': '15%' }}>{t('category')}</th>
									<th style={{ 'width': '15%' }}>{t('manufacturer')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '25%' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{item.row_number}. {item.name}</td>
										<td>{item.country_name}</td>
										<td>{item.category_name}</td>
										<td>{item.manufacturer_name}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="d-flex justify-content-end">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('country')}</label>
							<Select
								isClearable
								options={countries}
								value={countries.find(option => option.id === selectedItem.country_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'country_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('manufacturer')}</label>
							<Select
								isClearable
								options={manufacturers}
								value={manufacturers.find(option => option.id === selectedItem.manufacturer_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'manufacturer_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('category')}</label>
							<Select
								isClearable
								components={{ GroupHeading: GroupHead, Option }}
								options={categories}
								value={categoriesInitial.find(option => option.id === selectedItem.category_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'category_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('analogs')}</label>
							<Select
								isClearable
								options={analogs}
								value={analogs.find(option => option.id === selectedItem.analog_id) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'analog_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index