import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

import { SET_ACCOUNT } from 'store/account/action'
import { GUESTPOST } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz.png'

function Login() {
	const { i18n, t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [user, setUser] = useState({ "login": "", "password": "" })

	async function handleSubmit(e) {
		e.preventDefault();

		var sendData = {
			login: user.login.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		const response = await GUESTPOST('/login', sendData, { loader: true })

		if (response) {
			dispatch(SET_ACCOUNT(response))
			localStorage.setItem("login", user.login.toLowerCase());
			localStorage.setItem("role", response.role);
			localStorage.setItem('token', response.token)

			switch (response.role) {
				case 'ROLE_ADMIN':
					navigate("/admin/dashboard")
					break;
				case 'ROLE_OWNER':
					navigate("/admin/organization-products")
					break;
				default:
					toast.error("Нету доступа")
					break;
			}
		}
	}

	function changeLanguage(language = 'uz') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz') {
			localStorage.setItem('lang', 'uz');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'russian' }
		else if (locale === 'uz') return { flag: uzLatn, lang: 'uzbek_latn' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('logging_in')}</h3>
									</div>
									<div className="p-2">
										<form className="form-horizontal" autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
											<input className="login-input"
												name="login"
												autoFocus
												value={user.login}
												placeholder={t('login')}
												onChange={(e) => setUser({ ...user, 'login': e.target.value.toLowerCase() })} />

											<input className="login-input"
												name="password"
												type="password"
												placeholder={t('password')}
												value={user.password}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

											<div className="text-center">
												<button type="submit" className="login-button">
													{t('enter')}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="position-absolute lang-position">
						<Dropdown className="cursor-pointer d-flex cursor">
							<Dropdown.Toggle as={LanguageToggle}>
								<div className="d-flex">
									<div className="vertical-center">
										<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
									</div>
									<span>{t(getCurrentLocale().lang)}</span>
								</div>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{i18n.language !== 'ru' &&
									<Dropdown.Item onClick={() => changeLanguage("ru")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
											<span>{t('russian')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz' &&
									<Dropdown.Item onClick={() => changeLanguage("uz")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz" width="24" height="16" /></div>
											<span>{t('uzbek_latn')}</span>
										</div>
									</Dropdown.Item>
								}

							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="about-mdokon position-absolute">
						{t('information')}: +998 99 803-50-34 <br />
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default Login;
