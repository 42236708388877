import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatDate, regexPhoneNumber, returnSign, formatDateBackend, formatMoney, findKeyFromArrayByValue } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const telegramStatuses = [
		{
			'id': 'member', 'name': t('active')
		},
		{
			'id': 'kicked', 'name': t('blocked')
		}
	]
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: null,
		registration_type: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/clients',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/clients"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.telegram_status)
			urlParams += returnSign(urlParams) + 'telegram_status=' + filterDataCopy.telegram_status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/clients${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setData(response)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var filterUrl = `/clients/excel${filterData.urlParams}`

		var excelHeaders = [[t('phone'), t('name')]]
		const response = await GET(filterUrl)
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i]['phone'])
			excelItems.push(response[i]['name'])
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('clients') + ".xlsx");
	}

	function resetFilter() {
		var filterDataCopy = {
			start_date: null,
			end_date: null,
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex">
				<h4 className="vertical-center">
					{t('clients')} [{data?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={telegramStatuses}
													value={telegramStatuses.find(option => option.id === filterData.telegram_status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'telegram_status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => ""}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2" title="EXCEL"
									onClick={() => exportToExcel()}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>

						<div className="vertical-center">
							<h5 className="d-flex justify-content-between">
								<b className="me-3">{t('telegram')}:</b>
								<div>
									<span className="text-success">{formatMoney(data.total_member)}</span> |
									<span className="text-danger">{formatMoney(data.total_kicked)}</span>
								</div>
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.telegram_status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(telegramStatuses, 'id', filterData.telegram_status, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
									</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№ {t('phone')}</th>
									<th>{t('name')}</th>
									<th>{t('type')}</th>
									<th className="text-center">{t('date')}</th>
									<th className="text-center">{t('last_login')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td title={`ID: ${item.id}`}>
											{item.row_number}. {regexPhoneNumber(item.phone)}
										</td>
										<td>{item.name}</td>
										<td>
											{item.type === 1 &&
												<span>{t('buyer')}</span>
											}
											{item.type === 2 &&
												<span>{t('saler')}</span>
											}
											{item.type === 3 &&
												<span>{t('buyer_and_saler')}</span>
											}
										</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">{formatDate(item.last_login_at)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
