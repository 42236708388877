import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from 'react-redux'

import Index from "./routes/index"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/css/bootstrap.css'
import './assets/css/bootstrap-override.css'
import './assets/css/topbar.css'
import './assets/css/verical.css'
import './assets/css/datepicker.css'
import './assets/css/forms.css'
import "./assets/css/react-datepicker.css"
import './assets/css/style.css'
import './assets/css/delivery.css'
import './assets/css/font.css'
import './assets/css/iconscout.css'

function App() {
	const loader = useSelector(state => state.loader)

	useEffect(() => {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
		document.addEventListener("wheel", function (event) {
			if (document.activeElement.type === "number") {
				document.activeElement.blur();
			}
		});
		document.addEventListener("keypress", function (event) {
			var invalidChars = ["+", "e"];
			if (document.activeElement.type === "number" && invalidChars.includes(event.key)) {
				event.preventDefault();
			}
		});
	}, [])

	return (
		<>
			<Router>
				<Index />
			</Router>

			<ToastContainer
				position="bottom-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				theme="colored"
			/>

			{loader &&
				<div id="loading-bg">
					<div className="loading">
						<div className="effect-1 effects"></div>
						<div className="effect-2 effects"></div>
						<div className="effect-3 effects"></div>
					</div>
				</div>
			}
		</>
	);
}

export default App;
