import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST } from 'helpers/api'
import { regexLogin } from "helpers/helpers"

function ForgotPassword() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [user, setUser] = useState({ "login": "", "otp": "", "password": "" })
	const [show, setShow] = useState(false)

	async function sendConfirmationCode() {
		const response = await GUESTPOST('/send-confirmation-code', user, { loader: true })
		if (response) {
			setShow(true)
		}
	}

	async function changePassword() {
		const response = await GUESTPOST('/change-password', user, { loader: true })
		if (response) {
			toast.success(t('DATA_SUCCESFULLY_CHANGED'))
			navigate("/auth/login")
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('RECOVER_YOUR_PASSWORD')}</h3>
									</div>
									<input className="login-input"
										autoComplete="off"
										autoFocus
										name="login"
										value={user.login}
										placeholder={t('LOGIN')}
										onChange={(e) => setUser({ ...user, 'login': regexLogin(e.target.value) })} />

									{show ?
										<>
											<input className="login-input"
												autoComplete="off"
												name="otp"
												value={user.otp}
												placeholder={t('CODE')}
												onChange={(e) => setUser({ ...user, 'otp': e.target.value })} />

											<input className="login-input"
												autoComplete="off"
												name="password"
												value={user.password}
												placeholder={t('NEW_PASSWORD')}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

											<div className="text-center">
												<button type="button" className="login-button"
													onClick={changePassword}>
													{t('CHANGE_PASSWORD')}
												</button>
											</div>
										</>
										:
										<div className="text-center">
											<button type="button" className="login-button"
												onClick={sendConfirmationCode}>
												{t('GET_ACTIVATION_CODE')}
											</button>
										</div>
									}

									<div className="mt-2 text-center">
										<Link to="/auth/login" className="fw-medium text-white">{t('LOGGING_IN')}</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword
