import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UsersActivity from './UsersActivity'
import OrganizationsProducts from './OrganizationsProducts'
import UsersInterested from './UsersInterested'
import TopSearch from './TopSearch'
import Products from './Products'

function Index() {
	const { t } = useTranslation()

	const [activeTab, setActiveTab] = useState(1)

	return (
		<>
			<div className="tabs">
				<div className={"tab " + (activeTab === 1 ? 'active' : '')} onClick={() => setActiveTab(1)}>
					{t('Активность пользователя')}
				</div>
				<div className={"tab " + (activeTab === 2 ? 'active' : '')} onClick={() => setActiveTab(2)}>
					{t('Позиция на рынке')}
				</div>
				<div className={"tab " + (activeTab === 3 ? 'active' : '')} onClick={() => setActiveTab(3)}>
					{t('Заинтересованность')}
				</div>
				<div className={"tab " + (activeTab === 4 ? 'active' : '')} onClick={() => setActiveTab(4)}>
					{t('Топ поиска')}
				</div>
				<div className={"tab " + (activeTab === 5 ? 'active' : '')} onClick={() => setActiveTab(5)}>
					{t('Продукты')}
				</div>
			</div>

			<div>
				{activeTab === 1 &&
					<UsersActivity />
				}
				{activeTab === 2 &&
					<OrganizationsProducts />
				}
				{activeTab === 3 &&
					<UsersInterested />
				}
				{activeTab === 4 &&
					<TopSearch />
				}
				{activeTab === 5 &&
					<Products />
				}
			</div>
		</>
	)
}

export default Index